import DefaultPage from "../views/Default/index/DefaultPage"

export const defaultRoutes = [
    {
        path: "/404",
        name: "DefaultPage",
        component: DefaultPage,
        meta: {
            layout: 'default/index',
            title: 'Not found'
        }
    },
];
