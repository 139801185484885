<template>
  <transition :name="modalClass">
    <div :class="modalClass">
      <div
          :class="`${modalClass}-backdrop`"
          @click="closeModal"
      >
        <div :class="[{'simple-modal-scrollable': scrollable}, `${modalClass}-container`]">
          <div
              :class="`${modalClass}-content`"
              role="dialog"
              :aria-labelledby="headerId"
              :aria-describedby="bodyId"
              @click.stop
          >
            <header
                :id="headerId"
                :class="`${modalClass}-header`"
            >
              <slot
                  :id="bodyId"
                  name="header"
              >
                Modal title
              </slot>
            </header>
            <section :class="`${modalClass}-body`">
              <div class="simple-modal-body__wrapper">
                <p class="mb-3 title">выберете необходимое время</p>
                <div class="bottom-buttons bottom-buttons_size bottom-buttons_modal">
                  <div class="bottom-buttons__item"
                       v-for="(time, index) in arrClock"
                       :key="time"
                  >
                    <CustomButton
                        size="medium"
                        type="button"
                        stylesType="bordered-white btn"
                        :disabled="false"
                        :block="false"
                        @click = "(e) => onSelectedTime(e, time, index)"
                    >
                      {{ time }}
                    </CustomButton>
                  </div>
                  <div class="bottom-buttons__item">
                    <CustomButton
                        size="medium"
                        type="button"
                        stylesType="bordered-white btn"
                        :disabled="false"
                        :block="false"
                        @click="(e) => openTextArea(e)"
                    >
                      Другое время
                    </CustomButton>
                  </div>
                </div>
                <div class="mt-3" v-if="isOpen">
                  <textarea class="textarea" :value="selectedTime" @input="selectedTime = $event.target.value" rows="1"
                            name="text" placeholder="Введите время или комментарий..."/>
                </div>
              </div>
            </section>
            <footer :class="`${modalClass}-footer`">
              <slot name="footer"/>
              <div class="bottom-buttons justify-content-center mt-4">
                <div class="bottom-buttons__item">
                  <CustomButton
                      size="medium"
                      type="button"
                      stylesType="primary"
                      :disabled="false"
                      :block="false"
                      @handleClick="changeStatus('finished')"
                  >
                    Согласовать
                  </CustomButton>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import CustomButton from "@/ui/CustomButton";
import {mapMutations} from "vuex";
import {request} from "@/api/request";
export default {
  name: 'ModalWindow',
  components: {CustomButton},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    headerId: {
      type: String,
      required: true,
      default: null,
    },
    bodyId: {
      type: String,
      required: true,
      default: null,
    },
    modalClass: {
      type: String,
      default: 'simple-modal',
    },
  },
  data() {
    return {
      value: '',
      arrClock: ['1 час', '2 часа', '3 часа', '6 часов', '8 часов', '16 часов', '24 часа'],
      isOpen: false,
      selectedTime: ''
    }
  },
  mounted() {
    window.addEventListener('keydown', this.escCloseModal);
  },
  destroy() {
    window.removeEventListener('keydown', this.escCloseModal);
  },
  methods: {
    ...mapMutations({
      changeOrderStatus: 'serviceOrder/changeOrderStatus'
    }),
    changeStatus(status) {

      request('PATCH', `/api/v1/proxy/one-check-core/api/v1/service_orders/${this.$route.params.id}/set_required_time`, JSON.stringify({data: {'requiredTime': this.selectedTime}}))
          .then(() => {
            this.changeOrderStatus(status)
          })
          .catch(e => console.log(e))
      this.closeModal()
    },
    closeModal() {
      this.$emit('close');
    },
    escCloseModal(e) {
      if (this.show && e.key === 'Escape') {
        this.closeModal();
      }
    },
    openTextArea(e) {
      const btn = document.querySelectorAll('.btn')
      btn.forEach(item => item.classList.remove('green'))
      e.target.classList.add('green')
      this.selectedTime = ''
      this.isOpen = !this.isOpen
    },
    onSelectedTime(e, time, index) {
      const btn = document.querySelectorAll('.btn')
      btn.forEach(item => item.classList.remove('green'))
      btn[index].classList.add('green')
      this.isOpen = false
      this.selectedTime = time
    }
  },
};
</script>

<style lang="scss">
.simple-modal {
  &-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    transition: opacity 0.3s ease;
    z-index: 9999;
  }

  &-container {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: auto;
    margin: 16px;
    @media screen and (max-width: 560px) {
      margin: 0;
    }
  }

  &-body {

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: 560px) {
        width: unset;
        align-items: unset;
      }
    }

    .title {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;

      text-align: center;
      text-transform: uppercase;

      @media screen and (max-width: 560px) {
        font-size: 12px;
        line-height: 16px;
      }
    }

    .textarea {
      border: none;
      overflow: auto;
      resize: none;
      width: 563px;
      border-bottom: 1px solid #aaaaaa;
      border-radius: 8px 0 0 0;
      padding: 8px 0 8px 10px;

      @media screen and (max-width: 560px) {
        width: 350px;
      }
    }
  }


  &-scrollable {
    overflow-x: hidden;
    overflow-y: auto;
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 740px;
    margin: 1.75rem auto;
    padding: 20px 30px;
    border-radius: 5px;
    color: #000;
    background-color: #fff;
    box-sizing: border-box;
    transform: translate(0, 0);
    transition: all 0.3s ease;
    @media screen and (max-width: 560px) {
      height: 100%;
      margin: 0;
      padding: 20px 20px;
      justify-content: center;
      align-items: center;
    }
  }

  &-header {
    padding-bottom: 16px;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    @media screen and (max-width: 560px) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80px;
    text-align: center;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }

  &-enter-active,
  &-leave-active {
    transition: opacity 0.2s ease;
  }
}
.bottom-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: 114px;

  &__item {
    margin: 0 4px;
  }

  @media screen and (max-width: 560px) {
    flex-direction: column;
    margin: -4px;

    &__item {
      margin: 4px;
    }
  }
}
.bottom-buttons_size {

  width: 563px;
}

.bottom-buttons_modal {
  @media screen and (max-width: 560px) {
    width: 350px;
    height: 200px;
    flex-direction: row;
    justify-content: center;
  }

}
</style>
