<template>
    <div class="flex-grow-1">
        <textarea @keyup.enter="sendMessage" :value="value" @input="value = $event.target.value" rows="1" name="text" placeholder="Введите комментарий"/>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "NeededWorksTextarea",
      computed: {
        ...mapGetters('account', [
          "role"
        ])
      },
      methods: {
          sendMessage() {
            this.$emit("onMessage", this.value);
            this.value = ""
          },
      },
      data() {
          return {
            value: '',
          }
      }
    }
</script>

<style scoped lang="scss">
    textarea{
        resize: vertical;
        width: 100%;
        box-sizing: border-box;
        border-radius: 0;
        padding: 16px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        border: none;
        outline: none;
    }
</style>
