import MainLayout from "@/layouts/MainLayout";


export const mainLayoutRouter = [
    {
        path: "/:id",
        name: "MainLayout",
        component: MainLayout,
    },
];
