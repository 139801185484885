<template>
    <div class="highlighted-block">
        <h2 class="highlighted-block__title">{{ title }}</h2>
        <div class="highlighted-block__content">
            <slot/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BlockWithColoredHeader",
        props: ['title', 'color']
    }
</script>

<style scoped lang="scss">
    .highlighted-block{
        &__title{
            text-align: center;
            color: v-bind(color);
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            margin: 0 0 24px 0;
        }
    }
</style>