const state = () => ({
    order: {
        id: "",
        type: "service_order",
        attributes: {
            externalId: "123",
            status: "created",
            buyer: {
                id: '123',
                name: "Сергей",
                middleName: "Николаевич",
                surname: "Пестерев",
                address: "",
                phone: "+ 7 999 777 88 99"
            },
            car: {
                brand: "BMW",
                model: "X5",
                vin: "AGDHJRHDFS466HF",
                regNum: "E542АТ799",
                year: 2010,
                mileage: 15000
            },
            dealership: {
                entity: "Дилерский центр на Тверской",
                address: "ул. Тверская д.55 ",
                phone: "+7 999 999 99 99"
            },
            master: {
                id: "",
                name: "Новиков В.И",
                middleName: "",
                lastName: "",
                phone: "+ 7 999 777 11 22",
                email: ""
            },
            clientReason: "",
            works: [
                {
                    id: 1,
                    name: 'Замена передних сайлентблоков',
                    price: 2000,
                    measure: "",
                    count: 1,
                    discount: 0,
                },
            ],
            worksRecommended: [
                {
                    id: "",
                    name: "Замена фреона в кондиционере",
                    price: 1000,
                    measure: "",
                    count: 1.0,
                    discount: 10.0,
                    acceptedByBuyer: true,
                    messages: [
                        {
                            text: "Я мастер",
                            ownerRole: "service_master",
                            createdAt: "",
                            readAt: ""
                        },
                        {
                            text: "Я клиент",
                            ownerRole: "customer",
                            createdAt: "",
                            readAt: ""
                        }
                    ],
                    mediaObjects: [
                        {
                            id: "",
                            originalMediaObjectName: "",
                            contentType: "",
                            created: "",
                            size: 100,
                            term: ""
                        }
                    ],
                    status: "required"
                },
                {
                    id: "",
                    name: "Замена фар",
                    price: 1000,
                    measure: "",
                    count: 1.0,
                    discount: 10.0,
                    acceptedByBuyer: false,
                    messages: [
                        {
                            text: "Я мастер",
                            ownerRole: "service_master",
                            createdAt: "",
                            readAt: ""
                        },
                        {
                            text: "Я клиент",
                            ownerRole: "customer",
                            createdAt: "",
                            readAt: ""
                        }
                    ],
                    mediaObjects: [
                        {
                            id: "",
                            originalMediaObjectName: "",
                            contentType: "",
                            created: "",
                            size: 100,
                            term: ""
                        }
                    ],
                    status: "required"
                }
            ],
            parts: [
                {
                    id: 1,
                    name: 'Запчасть 1',
                    price: 2000,
                    measure: "",
                    count: 1,
                }
            ],
            partsRecommended: [
                {
                    id: "",
                    name: "Фреон",
                    price: 5000,
                    measure: "",
                    count: 1.0,
                    masterComment: "",
                    acceptedByBuyer: false,
                    status: ""
                }
            ],
            createdAt: ""
        }
    }
});

const actions = {};

const mutations = {
    changeWorkStatus(state, payload) {
        const titleWork = payload.title === 'work_recommended' ? 'worksRecommended' : 'partsRecommended'
        state.order.attributes = {...state.order.attributes, [titleWork] : payload.works}
    },
    changeOrderStatus(state, status) {
        state.order.attributes = {...state.order.attributes, status: status}
    },
    setData(state, data) {
        state.order = {...data.data}
    }
};

const getters = {
    externalId(state) {
        return state.order.attributes.externalId;
    },
    statusOrder(state) {
        return state.order.attributes.status;
    },
    dealership(state) {
        return state.order.attributes.dealership;
    },

    car(state) {
        return state.order.attributes.car;
    },

    master(state) {
        return state.order.attributes.master;
    },

    buyer(state) {
        return state.order.attributes.buyer;
    },

    works(state) {
        return state.order.attributes.works;
    },

    parts(state) {
        return state.order.attributes.parts;
    },
    worksRecommended(state) {
        return  state.order.attributes.worksRecommended.map(item => ({...item, title: 'work_recommended'}))
    },
    partsRecommended(state) {
        return state.order?.attributes.partsRecommended.map(item => ({...item, title: 'part_recommended'}))
    },
    maintenanceSum(state) {
        return state.order?.attributes.works.reduce((sum, current) => sum + current.price, 0) + state.order.attributes.parts.reduce((sum, current) => sum + current.price, 0)
    },
    worksSum(state) {
        return  state.order.attributes.status === 'created'
                ? state.order.attributes.worksRecommended.reduce((sum, current) => sum + current.price, 0) + state.order.attributes.partsRecommended.reduce((sum, current) => sum + current.price, 0)
                : state.order.attributes.worksRecommended.filter(item => item.acceptedByBuyer).reduce((sum, current) => sum + current.price, 0) + state.order.attributes.partsRecommended.filter(item => item.acceptedByBuyer).reduce((sum, current) => sum + current.price, 0)

    },
    orderId(state) {
        return state.order.id
    },
    acceptedByBuyerAll(state) {
        const acceptWork = state.order.attributes.worksRecommended.some(item => item.acceptedByBuyer === true)
        const acceptPart = state.order.attributes.partsRecommended.some(item => item.acceptedByBuyer === true)
        return acceptWork || acceptPart
    },

    isPaymentSuccess({order}){
        const payments = order.attributes.payments;
        if(Array.isArray(payments) && payments.length){
            return !!payments.find(item => {
                return item.status === 'complete' || item.status === 'hold'
             })
        }

        return false;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
