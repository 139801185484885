<template>
    <div class="cw-block">
        <div class="cw-block__title">
            {{ title }}
        </div>
        <div class="cw-block__content cw-block-content">
            <div v-for="item in items"
                 :key="item.id"
                 class="cw-block-content__item cw-work">
                <div class="cw-work__name">{{ item.name }}</div>
                <div class="cw-work__price">{{ item.price.toLocaleString('Ru-ru') }} ₽</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CompletedWorksBlock",
        props: ['items', 'title']
    }
</script>

<style scoped lang="scss">
    .cw-block{
        &__title{
            font-weight: 600;
            font-size: 16px;
            line-height: 28px;
            padding: 0 0 8px 0;
        }
        &__content{

        }
    }
    .cw-block-content{
        &__item{
            padding: 8px 0;
          .cw-work__price {
            white-space: nowrap;
          }
        }
    }
    .cw-work{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        &__name{

        }
        &__price{
            text-align: right;
        }
        @media screen and (max-width: 560px){
            font-size: 14px;
            line-height: 20px;
            &__name{
                flex-basis: 70%;
            }
            &__price{
                flex-basis: 30%;
            }
        }
    }
</style>
