<template>
  <div class="bottom-buttons">
    <div class="bottom-buttons__item">
      <CustomButton
          v-if="isShowSendToClientButton"
          size="large"
          type="button"
          stylesType="primary"
          :disabled="false"
          :block="false"
          @handleClick="changeStatus('client_confirm_wait')">
        Отправить клиенту
      </CustomButton>
      <CustomButton
          v-if="isShowAcceptButton"
          size="large"
          type="button"
          stylesType="primary"
          :disabled="false"
          :block="false"
          @handleClick="acceptButtonClick">
        Заказать работы
        <!-- Согласовать работы {{ worksSum }} ₽ -->
      </CustomButton>
      <CustomButton
          v-if="isShowAcceptTimeAndWorkButton"
          size="large"
          type="button"
          stylesType="primary"
          :disabled="false"
          :block="false"
          @handleClick="openModal">
        Согласовать время и работу
      </CustomButton>
    </div>

    <div v-if="isShowInstallmetButton" class="bottom-buttons__item">
      <CustomButton
          size="large"
          type="button"
          stylesType="green"
          :disabled="false"
          :block="false"
          @handleClick="openPaymentModal('installment')">
        Оформить рассрочку на {{ worksSum }} ₽
      </CustomButton>
    </div>

    <div v-if="isShowDeclineButton" class="bottom-buttons__item">
      <CustomButton
          size="large"
          type="button"
          stylesType="bordered-white"
          :disabled="false"
          :block="false"
          @handleClick="refuse">
        Отказаться
      </CustomButton>
    </div>

    <CustomButton
        v-if="isShowSuccessPaymentFakeButton"
        size="large"
        type="button"
        :stylesType="`fake-green${role === 'customer' ?'-bordered':''}`"
        :block="false">
      Оплачены работы на сумму {{ worksSum }} ₽
    </CustomButton>
  </div>
</template>

<script>
import CustomButton from "@/ui/CustomButton";
import {mapGetters, mapMutations} from "vuex";
import {request} from "@/api/request";

export default {
  name: "BottomButtons",

  components: {CustomButton},

  props: {
    paymentButtons: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  data() {
    return {
      supervisorsRoles: ['client_owner', 'client_admin', 'service_master', 'service_manager']
    }
  },

  computed: {
    ...mapGetters('serviceOrder', [
        "statusOrder",
        "worksRecommended",
        "partsRecommended",
        "worksSum",
        "acceptedByBuyerAll",
        "isPaymentSuccess"
    ]),
    ...mapGetters('account', [
      "role",
    ]),

    isShowSendToClientButton(){
      return this.supervisorsRoles.includes(this.role)
          && this.statusOrder === 'created'
    },

    isShowAcceptButton(){
      return this.role === 'customer'
          && (this.statusOrder === 'client_confirm_wait' || this.statusOrder ===  'client_reviewed')
          && this.acceptedByBuyerAll;
    },

    isShowInstallmetButton() {
      return this.role === 'customer'
          && (this.statusOrder === 'client_confirm_wait' || this.statusOrder ===  'client_reviewed')
          && this.acceptedByBuyerAll
          && this.paymentButtons.installment.enabled;
    },

    isShowAcceptTimeAndWorkButton(){
      return this.supervisorsRoles.includes(this.role)
          && this.statusOrder === 'client_confirm_got'
          && this.acceptedByBuyerAll
    },

    isShowDeclineButton(){
      return this.role === 'customer'
          && (this.statusOrder === 'client_confirm_wait' || this.statusOrder ===  'client_reviewed')
    },

    isShowSuccessPaymentFakeButton(){
      return this.statusOrder === 'client_confirm_got'
          && this.isPaymentSuccess;
    },
  },
  methods: {
    ...mapMutations({
      changeOrderStatus: 'serviceOrder/changeOrderStatus'
    }),

    changeStatus(status) {
      request('POST', `/api/v1/proxy/one-check-core/api/v1/service_orders/${this.$route.params.id}/set_status/${status}`)
          .then(() => this.changeOrderStatus(status))
          .catch(e => console.log(e))
    },

    openModal() {
      this.$emit('openModal')
    },

    openPaymentModal(type) {
      this.$emit('openPaymentModal', type)
    },

    acceptButtonClick(){
      if(this.paymentButtons.full.enabled) {
        this.openPaymentModal('full');
        return;
      }

      this.entitiesAccept();
    },

    entitiesAccept() {
      const workIds = [...this.worksRecommended].filter(item => item.acceptedByBuyer === true).map(item => item.id)
      const partIds = [...this.partsRecommended].filter(item => item.acceptedByBuyer === true).map(item => item.id)
      request('POST', `/api/v1/proxy/one-check-core/api/v1/service_orders/${this.$route.params.id}/entities_accept`, JSON.stringify({data: {workRecommendedIds: workIds, partRecommendedIds: partIds}}))
          .then(() => this.changeOrderStatus('client_confirm_got'))
          .catch(e => console.log(e))
    },
    refuse() {
      request('POST', `/api/v1/proxy/one-check-core/api/v1/service_orders/${this.$route.params.id}/entities_accept`, JSON.stringify({data: {workRecommendedIds: [], partRecommendedIds: []}}))
          .then(() => this.changeOrderStatus('client_confirm_got'))
          .catch(e => console.log(e))
    }
  },
}
</script>

<style scoped lang="scss">
.bottom-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -4px;

  &__item {
    margin: 0 4px;
  }

  @media screen and (max-width: 740px) {
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin: -4px;

    &__item {
      margin: 4px;
    }
  }
}
</style>
