<template>
    <transition :name="modalClass" v-show="isShow">
      <div :class="modalClass">
        <div
            :class="`${modalClass}-backdrop`"
            @click="closeModal"
        >
          <div :class="[{'simple-modal-scrollable': scrollable}, `${modalClass}-container`]">
            <div
                :class="`${modalClass}-content`"
                role="dialog"
                :aria-labelledby="headerId"
                :aria-describedby="bodyId"
                @click.stop
            >
              <header :id="headerId" :class="`${modalClass}-header`">
                <slot :id="bodyId" name="header" >
                  Modal title
                </slot>
              </header>
              <section :class="`${modalClass}-body`">
                <div class="simple-modal-body__wrapper">
                    <slot name="body" />
                </div>
              </section>
              <footer :class="`${modalClass}-footer`">
                <slot name="footer"/>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </template>

  <script>
  export default {
    name: 'UIModal',

    props: {
      modelValue: {
        type: Boolean,
        default: false,
      },
      scrollable: {
        type: Boolean,
        default: false,
      },
      headerId: {
        type: String,
        required: true,
        default: null,
      },
      bodyId: {
        type: String,
        required: true,
        default: null,
      },
      modalClass: {
        type: String,
        default: 'simple-modal',
      },
    },

    data() {
      return {
        isOpen: false,
      }
    },

    computed: {
        isShow: {
            get() {
                return this.modelValue;
            },

            set(val){
                return this.$emit('update:modelValue',val);
            },
        },
    },

    mounted() {
      window.addEventListener('keydown', this.escCloseModal);
    },

    destroy() {
      window.removeEventListener('keydown', this.escCloseModal);
    },

    methods: {
      closeModal() {
        this.isShow = false;
      },

      escCloseModal(e) {
        if (this.show && e.key === 'Escape') {
          this.closeModal();
        }
      },
    },
  };
  </script>

  <style lang="scss">
  .simple-modal {
    &-backdrop {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      transition: opacity 0.3s ease;
      z-index: 9999;
    }

    &-container {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: auto;
      margin: 16px;
      @media screen and (max-width: 560px) {
        margin: 0;
      }
    }

    &-body {
      &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media screen and (max-width: 560px) {
          width: unset;
          align-items: unset;
        }
      }

      .title {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;

        text-align: center;
        text-transform: uppercase;

        @media screen and (max-width: 560px) {
          font-size: 12px;
          line-height: 16px;
        }
      }

    &-scrollable {
      overflow-x: hidden;
      overflow-y: auto;
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      max-width: 740px;
      margin: 1.75rem auto;
      padding: 20px 30px;
      border-radius: 5px;
      color: #000;
      background-color: #fff;
      box-sizing: border-box;
      transform: translate(0, 0);
      transition: all 0.3s ease;
      @media screen and (max-width: 560px) {
        height: 100%;
        margin: 0;
        padding: 20px 20px;
        justify-content: center;
        align-items: center;
      }
    }

    &-header {
      padding-bottom: 16px;
      font-weight: 700;
      font-size: 36px;
      line-height: 44px;
      text-align: center;
      @media screen and (max-width: 560px) {
        font-size: 20px;
        line-height: 28px;
      }
    }

    &-footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 80px;
      text-align: center;
    }

    &-enter,
    &-leave-to {
      opacity: 0;
    }

    &-enter-active,
    &-leave-active {
      transition: opacity 0.2s ease;
    }
  }
}
</style>
