const STATUS_UNAUTHORIZED = "unauthorized";
const STATUS_AUTHORIZED = "authorized";
const ROLE_GUEST = "service_master";
const TYPE_GUEST = "guest";

const state = () => ({
    status: STATUS_UNAUTHORIZED,
    user: {
        role: ROLE_GUEST,
        name: "",
        last_name: "",
        email: "",
        client_id: "",
        clients: {},
        type: TYPE_GUEST
    }
});

const actions = {
    forceLogout({commit}) {
        commit('loggedOut');
    },
};

const mutations = {
    loggedIn(state, role) {
        state.status = STATUS_AUTHORIZED;
        state.user.role = role
    },
    loggedOut(state) {
        state.status = STATUS_UNAUTHORIZED;
        state.user = {
            user_id: "",
            role: "",
            name: "",
            last_name: "",
            email: "",
            client_id: "",
            type: "",
            clients: {},
        };

        localStorage.removeItem("auth_token");
    },
};

const getters = {
    isAuthorized(state) {
        return state.status === STATUS_AUTHORIZED;
    },
    role(state) {
        return state.user.role
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
