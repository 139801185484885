<template>
    <UIModal v-model="isShow" :scrollable="true">
        <template #header>
            <div class="header">
                Проверьте список работ
            </div>
        </template>
        <template #body>
            <section class="body">
                <div class="body--works-checkboxes">
                    <div class="body--title">Выполненные работы</div>
                    <div class="body--row" v-for="(work, index) in worksRecommended" :key="work.id">
                        <NeededWorkCheckbox
                            class="body--works-checkboxes_item"
                            :value="work.id"
                            :isChecked="work.acceptedByBuyer"
                            @change="(e) => onChangeCheckbox(index, e)"
                            :id="work.id"
                            :name="work.id"
                            :errors="null"
                            :is-rejected="false"
                            :disabled="false"
                            :workStatus="work.status"
                        >
                            {{ work.name }}
                        </NeededWorkCheckbox>
                        <div class="divider" />
                        <div>
                            <div class="body--works-checkboxes_price">
                            {{ work.price.toLocaleString('ru-Ru') }} ₽
                            </div>
                        </div>
                    </div>
                </div>

                <div class="body--works-checkboxes">
                    <div class="body--title">Запасные части</div>
                    <div class="body--row" v-for="(part, index) in partsRecommended" :key="part.id">
                        <NeededWorkCheckbox
                            class="body--works-checkboxes_item"
                            :value="part.id"
                            :isChecked="part.acceptedByBuyer"
                            @change="(e) => onChangeCheckbox(index, e)"
                            :id="part.id"
                            :name="part.id"
                            :errors="null"
                            :is-rejected="false"
                            :disabled="false"
                            :workStatus="part.status"
                        >
                            {{ part.name }}
                        </NeededWorkCheckbox>
                        <div class="divider" />
                        <div>
                            <div class="body--works-checkboxes_price">
                            {{ part.price.toLocaleString('ru-Ru') }} ₽
                            </div>
                        </div>
                    </div>
                </div>

                <div class="body--row amount">
                    <div>Итого</div>
                    <div class="divider"/>
                    <div>{{amountSum}}</div>
                </div>
            </section>
        </template>
        <template #footer>
            <div class="footer--button_container">
                <CustomButton
                    v-if="paymentType === 'installment'"
                    size="large"
                    type="button"
                    stylesType="green"
                    :disabled="false"
                    :block="false"
                    @handleClick="payInstallment">
                    Оформить рассрочку
                </CustomButton>

                <CustomButton
                    v-if="paymentType === 'full'"
                    size="large"
                    type="button"
                    stylesType="primary"
                    :disabled="false"
                    :block="false"
                    @handleClick="payFullOnline">
                    Оплатить Онлайн
                </CustomButton>

                <CustomButton
                    v-if="paymentType === 'full'"
                    size="large"
                    type="button"
                    stylesType="bordered-white"
                    :disabled="false"
                    :block="false"
                    @handleClick="payOffline">
                    Оплатить на месте
                </CustomButton>
            </div>
        </template>
    </UIModal>
</template>

<script>
import { mapGetters } from "vuex";
import {request} from "@/api/request";
import UIModal from '@/ui/Modal.vue';
import CustomButton from '@/ui/CustomButton.vue';
import NeededWorkCheckbox from "@/ui/NeededWorkCheckbox";

export default {
    components: {
        UIModal,
        CustomButton,
        NeededWorkCheckbox,
    },

    props: {
      modelValue: {
        type: Boolean,
        default: false,
      },

      paymentType: {
        type: String,

      }
    },

    computed: {
        ...mapGetters('serviceOrder', [
            "worksRecommended", "partsRecommended", "worksSum", "acceptedByBuyerAll"
        ]),

        isShow: {
            get() {
                return this.modelValue;
            },

            set(val){
                return this.$emit('update:modelValue',val);
            },
        },

        amountSum(){
          return `${(this.worksSum).toLocaleString('Ru-ru')} ₽`;
        }
    },

    methods: {
        async payInstallment() {
            await this.entitiesAccept();
            await request('GET', `/api/v1/proxy/one-check-core/api/v1/service_orders/${this.$route.params.id}/payment/start?installment`)
                .then(({data})=>{
                    window.location.replace(data.url)
                })
        },

        async payFullOnline() {
            await this.entitiesAccept();
            await request('GET', `/api/v1/proxy/one-check-core/api/v1/service_orders/${this.$route.params.id}/payment/start`)
                .then(({data})=>{
                    window.location.replace(data.url)
                })
        },

        payOffline(){
            this.entitiesAccept();
            this.isShow = false;
        },

        onChangeCheckbox(i, e) {
            const works = [...this.$props.works]
            works[i].acceptedByBuyer = e.target.checked
            this.changeWorkStatus({works, title: works[i].title})
        },

        async entitiesAccept() {
            const workIds = [...this.worksRecommended].filter(item => item.acceptedByBuyer === true).map(item => item.id)
            const partIds = [...this.partsRecommended].filter(item => item.acceptedByBuyer === true).map(item => item.id)
            await request('POST', `/api/v1/proxy/one-check-core/api/v1/service_orders/${this.$route.params.id}/entities_accept`, JSON.stringify({data: {workRecommendedIds: workIds, partRecommendedIds: partIds}}))
                .then(() => this.changeOrderStatus('client_confirm_got'))
                .catch(e => console.log(e))
        },
    }
}
</script>

<style lang="scss">
.header {
    margin-top: 64px;
}

.body {
    width: 100%;
    margin-top: 66px;

    &--title {
        border-bottom: 1px solid #EEEEEE;
        margin-top: 18px;
        margin-bottom: 10px;
        padding: 6px 16px;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
    }

    &--row {
        display: flex;
        padding: 6px 16px;
    }

    &--works-checkboxes {
        &_item {
            .checkboxBox{
                justify-content: flex-start;
                .formLabelText{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }

        &_price {
            white-space: nowrap;
        }
    }

    .divider {
        flex-grow: 1;
        width: 100%;
    }
}

.footer {
    &--button{
        &_container {
            display:flex;
            justify-content: center;

            .customButton {
                margin: 0;
            }

            .customButton + .customButton {
                margin-left: 23px;
            }
        }

        &_item {

        }
    }
}

.amount {
    white-space: nowrap;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    margin-top: 30px;
    margin-bottom: 49px;
}

</style>