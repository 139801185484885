import store from "../store";
import config from "config";

export const wrapError = function(response, parsedError){
    let err = new Error(response && response.statusText);
    err.code = response.status;

    if (parsedError && parsedError.errors && parsedError.errors.length > 0) {
        let error = parsedError.errors.shift();
        err.codeValue = error.code
        err.message = error.title;
    }
    return err

};

export const request = (method, path, data = null, download = false) => {
    const authToken = localStorage.getItem("auth_token") || "";
    const pixel = window["_KDX_UID"] || "";

    let options = {
        method: method,
        headers: {
            'X-Kodix-Pixel': pixel
        },
    };

    if (authToken) {
        options.headers['X-Auth-Token'] = authToken;
    }

    if (data) {
        options.body = data;
    }

    const request = new Request(`${config.apiHost || "https://api.smart-contract.digital"}${path}`, options);

    return fetch(request)
        .then(response => {
            if ((response.status === 401) || (response.status === 403)) {
                store.dispatch("account/forceLogout").then(() => {
                    window.location.reload()
                });
            }

            if( download ) {
                return response.blob().then( blob => {
                    const a = document.createElement('a');
                    a.href = URL.createObjectURL(blob);
                    a.download = "export.xlsx";
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();

                    return null;
                }).catch(e => {
                    console.error(e);
                    return Promise.reject(e);
                });
            }

            return response.text()
                .then(text => {
                    let data;

                    try {
                        data = text && JSON.parse(text);
                    } catch (e) {
                        console.error(e);
                        return Promise.reject(e);
                    }

                    if (!response.ok) {
                        return Promise.reject(wrapError(response,data));
                    }

                    return data;
                })
                .catch(e => {
                    console.error(e);
                    return Promise.reject(e);
                });
        });
};
