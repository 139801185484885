<template>
    <div class="car">
        <div class="car__name">{{ brand }} {{ model }}</div>
        <div class="car__number">{{ $t('right_column.reg_number') }} {{ number }}</div>
    </div>
</template>

<script>
    export default {
        props: [
            "brand",
            "model",
            "number"
        ]
    }
</script>

<style scoped lang="scss">
    .car{
        &__name{
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            margin: 0 0 8px 0;
        }
        &__number{
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
        }
    }
</style>