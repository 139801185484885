<template>
    <button :class="['customButton', stylesType, size, block ? 'block' : '']"
            :type="type || 'button'"
            :disabled="disabled"
            @click="$emit('handleClick')">
        <slot/>
    </button>
</template>

<script>
    export default {
        name: "CustomButton",

        props: {
            type: {
                type: String,
                required: false,
                default: 'button'
            },
            stylesType: {
                // white primary light bordered
                type: String,
                required: false,
            },
            disabled: {
                type: Boolean,
                required: false,
            },
            size: {
                // large big medium small tiny
                type: String,
                required: false,
                default: 'medium'
            },
            block: {
                type: Boolean,
                required: false,
            }
        }
    }
</script>

<style scoped lang="scss">
    .customButton{
        outline: none;
        border: none;
        text-transform: uppercase;
        transition: all ease-in-out 0.5s;
        box-sizing: border-box;
        margin: 0 auto;
        border-radius: 30px;
        font-family: 'Montserrat', sans-serif;
        display: flex;
        align-items: center;

        img{
            display: flex;
        }


        &.block{
            width: 100%;
        }

        &.large {
            padding: 14px 20px;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            /*@media screen and (max-width: 600px) {*/
            /*    font-size: 14px;*/
            /*    font-weight: 500;*/
            /*    padding: 14px 36px;*/
            /*}*/

            /*@media screen and (max-width: 400px) {*/
            /*    padding: 10px;*/
            /*    font-size: 12px;*/
            /*}*/
        }

        &.medium{
            padding: 10px 16px;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;

            /*@media screen and (max-width: 400px) {*/
            /*    padding: 10px;*/
            /*    font-size: 12px;*/
            /*}*/
        }

        &.tiny{
            padding: 4px 12px;
            font-size: 12px;
            font-weight: 500;
            border-radius: 2px;
        }

        &.small{
            padding: 2px 16px;
            font-size: 10px;
            line-height: 20px;
            border-radius: 13px;
        }
        &.close{
            padding: 6px;
            border-radius: 6px;
        }

        &.white{
            color: rgb(114, 121, 134);
            background: white;
        }

        &.light{
            color: #1c69d4;
            background: rgba(28, 105, 212, 0.07);
        }

        &.green{
            background: #97CF26 !important;
            color: white !important;
          border: 1px solid #97CF26 !important;
        }
        &.primary{
            background: #5068FE;
            color: white;
        }

        &.bordered-white{
            background: white;
            color: #1c69d4;
            border: 1px solid #5068FE;
        }

        &:disabled{
            opacity: 0.4;
            cursor: not-allowed;
        }

        &.fake-green {
            transform: none !important;
            background: #97CF26 !important;
            color: white !important;
            transition: none !important;
            cursor: inherit !important;
            border: 1px solid #97CF26 !important;
        }

        &.fake-green-bordered {
            transform: none !important;
            background: white !important;
            color: #97CF26 !important;
            transition: none !important;
            cursor: inherit !important;
            border: 1px solid #97CF26 !important;
        }

        &:not(:disabled):hover{
            cursor: pointer;
            transform: scale(1.02);
            transition: all ease-in-out 0.5s;
        }
    }
</style>
