<template>
    <div class="mobile-menu">
        <RightColumn :show-logo="false"/>
    </div>
</template>

<script>
    import RightColumn from "@/components/RightColumn/RightColumn";
    export default {
        name: "MobileMenu",
        components: {RightColumn}
    }
</script>

<style scoped lang="scss">
    .mobile-menu{
        overflow-y: auto;
        height: 100%;
        padding: 20px 16px;
    }
</style>