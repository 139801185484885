<template>
    <div style="display: flex; align-items: center; justify-content: center; flex-direction: column; margin-top: 40px">
        <h1>Page not found</h1>
        <br>
        <router-link to="/">
            <v-icon x-large>home</v-icon>
        </router-link>
    </div>
</template>

