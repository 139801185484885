<template>
    <div :class="['comment', type]">
        <div class="comment__label">
            <template v-if="type === 'master'">Комментарий мастера</template>
            <template v-else>Комментарий клиента</template>
        </div>
        <div class="comment__value">{{ value }}</div>
        <div></div>
    </div>
</template>

<script>
    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: "Comments",
        props: ['value', 'type']
    }
</script>

<style scoped lang="scss">
    .comment{
        background: white;
        padding: 8px 16px;
        border: 1px solid #ddd;
        box-shadow: 0px 0px 7px 1px #ddd;

        &.client{
            text-align: right;
        }


        &__label{
            color: #aaa;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            margin: 0 0 6px 0;
        }

        &__value{
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
        }

    }
</style>