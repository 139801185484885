<template>
    <div class="icon-label">
        <div :class="`icon icon-${icon}`"/>
        <div v-if="!tel" class="text">{{ text }}</div>
        <div v-if="tel" class="text"><a :href="`tel:${text}`">{{ text }}</a> </div>
    </div>
</template>

<script>
    export default {
        props: [
            "icon",
            "text",
            "tel"
        ]
    }
</script>

<style scoped>
    .icon-label {
        display: flex;
        align-items: center;
    }

    .text {
        margin-left: 12px;
    }

    .icon {
        width: 16px;
        height: 16px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .icon-clock {
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.99999 13.6668C3.31799 13.6668 0.333328 10.6822 0.333328 7.00016C0.333328 3.31816 3.31799 0.333496 6.99999 0.333496C10.682 0.333496 13.6667 3.31816 13.6667 7.00016C13.6667 10.6822 10.682 13.6668 6.99999 13.6668ZM6.99999 12.3335C9.94551 12.3335 12.3333 9.94568 12.3333 7.00016C12.3333 4.05464 9.94551 1.66683 6.99999 1.66683C4.05448 1.66683 1.66666 4.05464 1.66666 7.00016C1.66666 9.94568 4.05448 12.3335 6.99999 12.3335ZM7.66666 7.00016H10.3333V8.3335H6.33333V3.66683H7.66666V7.00016Z' fill='%23AAAAAA'/%3E%3C/svg%3E");
    }

    .icon-phone {
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.04888 6.02387C5.74395 7.24496 6.7552 8.25621 7.97629 8.95127L8.63111 8.03424C8.84905 7.72905 9.26068 7.63489 9.58962 7.81498C10.6373 8.38751 11.7942 8.73185 12.9844 8.82535C13.3697 8.85588 13.6667 9.17742 13.6667 9.56387V12.8691C13.6667 13.249 13.3794 13.5673 13.0015 13.6061C12.6089 13.6468 12.2133 13.6668 11.8148 13.6668C5.47407 13.6668 0.333328 8.52609 0.333328 2.18535C0.333328 1.78683 0.353328 1.39127 0.394069 0.998681C0.432819 0.620756 0.7512 0.333496 1.13111 0.333496H4.43629C4.82273 0.333496 5.14428 0.630484 5.17481 1.01572C5.26831 2.20593 5.61264 3.36289 6.18518 4.41053C6.36526 4.73948 6.2711 5.15111 5.96592 5.36905L5.04888 6.02387ZM3.18074 5.5372L4.58814 4.53201C4.18872 3.66986 3.91507 2.75486 3.77555 1.81498H1.82222C1.81777 1.93794 1.81555 2.06164 1.81555 2.18535C1.81481 7.70831 6.29185 12.1853 11.8148 12.1853C11.9385 12.1853 12.0622 12.1831 12.1852 12.1779V10.2246C11.2453 10.0851 10.3303 9.81143 9.46814 9.41201L8.46296 10.8194C8.05826 10.6622 7.66518 10.4765 7.28666 10.2639L7.2437 10.2394C5.79079 9.41255 4.58761 8.20936 3.76074 6.75646L3.73629 6.7135C3.52363 6.33497 3.33798 5.94189 3.18074 5.5372Z' fill='%23AAAAAA'/%3E%3C/svg%3E");
    }

    .icon-location {
        background-image: url("data:image/svg+xml,%3Csvg width='13' height='16' viewBox='0 0 13 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.26042 13.5477L9.61198 10.1304C11.4628 8.24316 11.4627 5.18348 9.61176 3.29635C7.76085 1.40922 4.76 1.40922 2.90909 3.29635C1.05818 5.18348 1.05808 8.24316 2.90886 10.1304L6.26042 13.5477ZM6.26042 15.5L1.95146 11.1066C-0.428273 8.68016 -0.428257 4.74619 1.9515 2.3198C4.33125 -0.106599 8.18959 -0.106599 10.5693 2.3198C12.9491 4.74619 12.9491 8.68016 10.5694 11.1066L6.26042 15.5ZM6.26042 8.09386C7.00831 8.09386 7.61459 7.4757 7.61459 6.71315C7.61459 5.9506 7.00831 5.33244 6.26042 5.33244C5.51254 5.33244 4.90625 5.9506 4.90625 6.71315C4.90625 7.4757 5.51254 8.09386 6.26042 8.09386ZM6.26042 9.47457C4.76465 9.47457 3.55209 8.23824 3.55209 6.71315C3.55209 5.18806 4.76465 3.95173 6.26042 3.95173C7.75619 3.95173 8.96875 5.18806 8.96875 6.71315C8.96875 8.23824 7.75619 9.47457 6.26042 9.47457Z' fill='%23AAAAAA'/%3E%3C/svg%3E");
    }

    .text{
        /*
        от родителя
         */
    }
</style>
