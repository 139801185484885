<template>
    <div class="mobile-header">
        <div class="mobile-header__item">
            <CustomButton
                    v-if="!isMobileMenuOpened"
                    size="small"
                    type="button"
                    stylesType="primary"
                    :disabled="false"
                    :block="false"
                    @handleClick="$emit('toggleMobileMenu')">
                Данные о записи
            </CustomButton>
            <CustomButton
                    v-else
                    size="close"
                    type="button"
                    stylesType="primary"
                    :disabled="false"
                    :block="false"
                    @handleClick="$emit('toggleMobileMenu')">
                <img src="../../icons/close.svg" alt="close" title="close">
            </CustomButton>
        </div>
        <div class="mobile-header__item">
            <img
                    src="../../assets/bmw.png"
                    alt="bmw"
                    title="bmw"
                    class="mobile-logo">
        </div>
    </div>
</template>

<script>
    import CustomButton from "@/ui/CustomButton";
    export default {
        name: "MobileHeader",
        components: {CustomButton},
        props: ['isMobileMenuOpened'],
        methods: {
            clickOnMe(){
                console.log('c o m')
            }
        }
    }
</script>

<style scoped lang="scss">
    .mobile-header{
        background: #F8F8F8;
        padding: 20px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .mobile-logo{
        height: 32px;
    }
</style>
