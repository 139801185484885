<template>
  <div class="nw-block">
    <div class="nw-block__header">{{ title }}</div>
    <div class="nw-block__content nwb-content">
      <div class="nwb-content__item"
           v-for="(work, index) in works"
           :key="work.id">
        <div class="needed-work">
          <div class="needed-work__row">
            <div class="needed-work-header">
              <div class="needed-work-header__action">
                <!--                                 :is-rejected="true" для красного чекбокса-->
                <NeededWorkCheckbox
                    v-if="role === 'customer' && (statusOrder === 'client_confirm_wait' || statusOrder ===  'client_reviewed')"
                    :value="work.id"
                    :isChecked="work.acceptedByBuyer"
                    @change="(e) => onChangeCheckbox(index, e)"
                    :id="work.id"
                    :name="work.id"
                    :errors="null"
                    :is-rejected="false"
                    :disabled="false"
                    :workStatus="work.status"
                >
                  {{ work.name }}
                </NeededWorkCheckbox>
                <NeededWorkCheckbox
                    v-if="(role === 'customer' && ['created','client_confirm_got', 'finished'].includes(statusOrder)) || (['client_owner', 'client_admin', 'service_master', 'service_manager'].includes(role) && ['client_confirm_wait', 'client_reviewed', 'client_confirm_got','finished'].includes(statusOrder))"
                    :value="work.id"
                    :isChecked="work.acceptedByBuyer"
                    :id="work.id"
                    :name="work.id"
                    :errors="null"
                    :is-rejected="!work.acceptedByBuyer"
                    :disabled="false"
                    :notCheck="true"
                    :workStatus="work.status"
                >
                  {{ work.name }}
                </NeededWorkCheckbox>
                <NeededWorkSwitch
                    v-if="['client_owner', 'client_admin', 'service_master', 'service_manager'].includes(role) && statusOrder === 'created'"
                    @toggle="(e) => onChangeSwitch(e, index)"
                    :is-enabled="work.status === 'required'"
                    :workStatus="work.status"
                >
                  {{ work.name }}
                </NeededWorkSwitch>
                <img src="../icons/sos.svg"
                     alt="sos"
                     title="sos"
                     v-if="work.isSos">
              </div>
              <div class="needed-work-header__price">
                {{ work.price.toLocaleString('ru-Ru') }} ₽
              </div>
            </div>
          </div>
          <div class="needed-work__row">
            <div class="needed-work__description">
              <span class="needed-work__description-title">
                {{ work.description }}
              </span>
            </div>
          </div>
          <div class="needed-work__row" v-if="work.messages">
            <div class="needed-work-comments">
              <div v-for="(comment, index) in work.messages"
                   class="needed-work-comments__item"
                   :key="index">
                <Comments
                    v-if="['client_owner', 'client_admin', 'service_master', 'service_manager'].includes(comment.ownerRole)"
                    :value="comment.text" type="master"/>
                <Comments v-if="comment.ownerRole === 'customer'" :value="comment.text" type="client"/>
              </div>
            </div>
          </div>
          <div class="needed-work__row" v-if="work.masterComment?.length > 0">
            <div class="needed-work-comments">
              <div class="needed-work-comments__item">
                <Comments
                    :value="work.masterComment" type="master"/>
              </div>
            </div>
          </div>
          <div class="needed-work__row" v-if="work.mediaObjects">
            <NeededWorksSlider :images="work.mediaObjects" :work='work' :works="works" :index="index" :orderId='orderId'
                               :statusOrder='statusOrder' :role="role" />
          </div>
          <div class="needed-work__row d-flex">
            <div :class="['photo-uploader__wrapper', !work.mediaObjects ? 'mr-auto' : '']"
                 v-if="statusOrder === 'created' && ['client_owner', 'client_admin', 'service_master', 'service_manager'].includes(role) && work.mediaObjects?.length === 0">
              <div class="photo-uploader__icon">
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_621_7741)">
                    <path
                        d="M3.625 8.83317C3.625 9.40609 4.09375 9.87484 4.66667 9.87484C5.23958 9.87484 5.70833 9.40609 5.70833 8.83317V6.74984H7.79167C8.36458 6.74984 8.83333 6.28109 8.83333 5.70817C8.83333 5.13525 8.36458 4.6665 7.79167 4.6665H5.70833V2.58317C5.70833 2.01025 5.23958 1.5415 4.66667 1.5415C4.09375 1.5415 3.625 2.01025 3.625 2.58317V4.6665H1.54167C0.96875 4.6665 0.5 5.13525 0.5 5.70817C0.5 6.28109 0.96875 6.74984 1.54167 6.74984H3.625V8.83317Z"
                        fill="white"/>
                    <path
                        d="M14.0417 18.2085C15.7675 18.2085 17.1667 16.8094 17.1667 15.0835C17.1667 13.3576 15.7675 11.9585 14.0417 11.9585C12.3158 11.9585 10.9167 13.3576 10.9167 15.0835C10.9167 16.8094 12.3158 18.2085 14.0417 18.2085Z"
                        fill="white"/>
                    <path
                        d="M22.375 6.74984H19.0729L17.7813 5.34359C17.5871 5.13046 17.3507 4.96016 17.087 4.84357C16.8234 4.72699 16.5383 4.66668 16.25 4.6665H9.58333C9.76042 4.979 9.875 5.32275 9.875 5.70817C9.875 6.854 8.9375 7.7915 7.79167 7.7915H6.75V8.83317C6.75 9.979 5.8125 10.9165 4.66667 10.9165C4.28125 10.9165 3.9375 10.8019 3.625 10.6248V21.3332C3.625 22.479 4.5625 23.4165 5.70833 23.4165H22.375C23.5208 23.4165 24.4583 22.479 24.4583 21.3332V8.83317C24.4583 7.68734 23.5208 6.74984 22.375 6.74984ZM14.0417 20.2915C11.1667 20.2915 8.83333 17.9582 8.83333 15.0832C8.83333 12.2082 11.1667 9.87484 14.0417 9.87484C16.9167 9.87484 19.25 12.2082 19.25 15.0832C19.25 17.9582 16.9167 20.2915 14.0417 20.2915Z"
                        fill="white"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_621_7741">
                      <rect x="0.5" y="0.5" width="25" height="25" rx="6" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </div>

              <form class="photo-uploader__form">
                <input type="file" class="photo-uploader__input" @change="uploaded($event, work, index)">
              </form>

            </div>
            <NeededWorksTextarea v-if="['client_owner', 'client_admin', 'service_master', 'service_manager', 'customer'].includes(role)  && title === 'Необходимые работы'"
                                 @onMessage="(e)=> addComment(index, e)"/>
            <NeededWorksTextarea v-if="['client_owner', 'client_admin', 'service_master', 'service_manager'].includes(role) && title === 'Необходимые запасные части' && work.masterComment?.length === 0"
                                 @onMessage="(e)=> addMasterComment(index, e)"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NeededWorkCheckbox from "@/ui/NeededWorkCheckbox";
import Comments from "@/components/Comments";
import NeededWorksTextarea from "@/components/NeededWorksTextarea";
import NeededWorksSlider from "@/components/NeededWorksSlider";
import NeededWorkSwitch from "@/ui/NeededWorkSwitch";
import {mapGetters, mapMutations} from "vuex";
import {request} from "@/api/request";

export default {
  name: "NeededWorksBlock",
  components: {NeededWorksTextarea, Comments, NeededWorkSwitch, NeededWorkCheckbox, NeededWorksSlider},
  props: ['works', 'title', 'statusOrder', 'orderId'],

  data() {
    return {
      isSwitchEnabled: this.works,
    }
  },
  computed: {
    ...mapGetters('account', [
      "role"
    ]),
    ...mapGetters('serviceOrder', [
      "statusOrder"
    ])
  },
  methods: {
    ...mapMutations({
      changeWorkStatus: 'serviceOrder/changeWorkStatus'
    }),
    onChangeSwitch(e, i) {
      const works = [...this.$props.works]
      works[i].status = e ? 'required' : 'recommended'
      this.changeWorkStatus({works, title: works[i].title})
      const data = {
        id: works[i].id,
        name: works[i].name,
        description: works[i].description,
        discount: works[i].discount,
        status: works[i].status,
        price: works[i].price,
        measure: works[i].measure,
        count: works[i].count
      }
      request('PATCH', `/api/v1/proxy/one-check-core/api/v1/service_orders/${this.$route.params.id}/${works[i].title === 'work_recommended' ? 'recommended_works' : 'recommended_parts'}/${works[i].id}`, JSON.stringify({data: data}))
    },
    onChangeCheckbox(i, e) {

      const works = [...this.$props.works]
      works[i].acceptedByBuyer = e.target.checked
      this.changeWorkStatus({works, title: works[i].title})
    },
    addComment(i, e) {
      const works = [...this.$props.works]
      request("POST", `/api/v1/proxy/one-check-core/api/v1/service_orders/${this.$props.orderId}/entities/${works[i].title}/${works[i].id}/messages`, JSON.stringify({data: {text: e}}))
          .then(() => {
            works[i].messages.push({
              text: e,
              ownerRole: this.role,
              createdAt: +new Date(),
              readAt: ""
            })
            this.changeWorkStatus({works, title: works[i].title})
          })
          .catch(e => console.log(e))

    },
    addMasterComment(i, e) {
      const works = [...this.$props.works]
      works[i].masterComment = e
      const part = {
        "id":works[i].id,
        "name":works[i].name,
        "price": 1.0,
        "measure": works[i].measure,
        "count": 1.1,
        "status": "required",
        "masterComment": e
      }
      request("PATCH", `/api/v1/proxy/one-check-core/api/v1/service_orders/${this.$props.orderId}/recommended_parts/${works[i].id}`, JSON.stringify({data: part}))
          .then(() => {
            this.changeWorkStatus({works, title: works[i].title})
          })
          .catch(e => console.log(e))
    },
    uploaded(e, work, index) {
      const file = new FormData()
      file.append('file', e.target.files[0])

      request("POST", `/api/v1/proxy/one-check-core/api/v1/service_orders/${this.$props.orderId}/entities/${work.title}/${work.id}/media_objects/upload`, file)
          .then(r => {
            const works = [...this.$props.works]
            works[index].mediaObjects.push(r.data)
            this.changeWorkStatus({works, title: works[index].title})
          })
          .catch(e => console.log(e))
    },
  }
}
</script>

<style scoped lang="scss">
.nw-block {
  &__header {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    padding: 0 0 8px 0;
    @media screen and (max-width: 950px) {
      padding: 0 16px 8px 16px;
    }
  }
}

.nwb-content {
  &__item:not(:last-child) {
    margin: 0 0 24px 0;
  }
}

.needed-work {
  background: #F8F8F8;
  padding: 16px 24px;
  @media screen and (max-width: 560px) {
    padding: 16px;
  }

  &__row:not(:last-child) {
    margin: 0 0 16px 0;
  }
}

.needed-work-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__action {
    display: flex;
    align-items: center;

    img {
      margin-left: 8px;
    }
  }

  &__price {
    text-align: right;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .needed-work-header__price {
    white-space: nowrap;
    margin-left: 5px;
  }

  @media screen and (max-width: 560px) {
    &__action {
      flex-basis: 80%;
    }
    &__price {
      flex-basis: 20%;
    }
  }
}

.needed-work__description {
  padding: 0 40px;
  @media screen and (max-width: 560px) {
    padding: 0 20px;
  }
}

.photo-uploader__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #97CF26;
  border: 1px solid #97CF26;
  margin-right: 15px;
  border-radius: 6px;

  &.mr-auto {
    margin-right: auto;
  }

  .photo-uploader__icon {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .photo-uploader__form {
    position: absolute;
    width: inherit;
    height: inherit;
  }

  .photo-uploader__input {
    cursor: pointer;
    z-index: 2;
    width: inherit;
    height: inherit;
    opacity: 0;

    &::file-selector-button {
      display: none;
    }
  }

  .photo-uploader__text {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #97CF26;;
  }
}
</style>
