
import {createRouter, createWebHashHistory} from "vue-router";
// import Meta from 'vue-meta';
// import store from "../store/index"


import {defaultRoutes} from './default';
import {mainLayoutRouter} from "@/router/mainLayoutRouter";

let routes = [];

for (let route of [
    ...mainLayoutRouter,
    ...defaultRoutes
]) {
    routes.push(route);
}

const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
});

// router.onError(e => {
//     store.dispatch("loader/hide");
//     store.dispatch("notification/error", e);
// });

// router.afterEach((to, from) => {
//     store.dispatch("loader/hide");
// });

// beforeEach см в smart_contracts.js (всегда) и см. в inside.js и auth.js только при успешном попадании на роут

export default router;
