<template>
    <div class="dealership-info">
        <div class="dealership-info__name">{{ name }}</div>
        <div class="dealership-properties">
            <div class="dealership-properties__row">
                <IconLabel :text="address" icon="location"/>
            </div>
            <div class="dealership-properties__row">
                <IconLabel text="с 10:00 до 21:00" icon="clock"/>
            </div>
            <div class="dealership-properties__row">
                <IconLabel tel='tel' :text="phone" icon="phone"/>
            </div>
        </div>
    </div>
</template>

<script>
    import IconLabel from "./IconLabel";

    export default {
        props: [
            "name",
            "address",
            "phone"
        ],

        components: {
            IconLabel,
        }
    }
</script>

<style scoped lang="scss">
    .dealership-info{
        &__name{
            margin: 0 0 32px 0;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
        }
    }

    .dealership-properties{
        &__row{
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }
        &__row:not(:last-child){
            margin: 0 0 16px 0;
        }
    }
</style>
