<template>
  <div class="completed-works">
    <div class="completed-works__header">
      <div class="cw-header">
        <div class="cw-header__item cw-action" @click="isOpened = !isOpened">
          Техническое обслуживание
          <img :class="[isOpened ? 'opened' : 'closed']"
               src="../icons/arrow_down.svg"
               alt="arrow"
               title="arrow">
        </div>
        <div class="cw-header__item cw-totals">
          {{ maintenanceSum }} Р
        </div>
      </div>
    </div>
    <div :class="['completed-works__content', 'cw-content', isOpened ? 'opened' : 'closed']">
      <div class="cw-content__block">
        <CompletedWorksBlock
            :items="works"
            title="Выполненные работы"
        />
      </div>
      <div class="cw-content__block">
        <CompletedWorksBlock
            :items="parts"
            title="Запасные части"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CompletedWorksBlock from "./CompletedWorksBlock";
import {mapGetters} from "vuex";

export default {
  name: "CompletedWorks",
  props: ["works", "parts"],
  components: {
    CompletedWorksBlock
  },
  computed: {
  ...mapGetters('serviceOrder', [
      "maintenanceSum"
    ])
  },

  data() {
    return {
      isOpened: false,
    }
  },

}
</script>

<style scoped lang="scss">
.cw-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  @media screen and (max-width: 560px) {
    font-size: 18px;
    line-height: 24px;
  }
}

.cw-action {
  transition: all ease-in-out 0.5s;

  @media screen and (max-width: 560px) {
    flex-basis: 50%;
    display: flex;
    align-items: baseline;
  }


  img {
    &.opened {
      transform: rotate(180deg);
    }

  }

  &:hover {
    transform: scale(1.01);
    cursor: pointer;
  }
}

.cw-totals {
  text-align: right;
  white-space: nowrap;
}

.cw-content {
  height: 0;
  overflow: hidden;

  &.closed {

  }

  &.opened {
    height: 100%;
    overflow: auto;
    transition: height linear 0.5s;
  }

  &__block {
    padding: 20px 0;
  }

  &__block:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
}
</style>
