import {createStore} from "vuex";
import createPersistedState from "vuex-persistedstate"

import account from "./modules/account";
import serviceOrder from "./modules/service_order";

export default createStore({
    modules: {
        account,
        serviceOrder
    },
    plugins: [
        createPersistedState({
            key: "one-check-detail",
            paths: ["account"],
        })
    ]
})