<template>
  <div>
    <div v-if="!isAuthorized">
      <LoginPage :clientId="clientId" @onAuth="getData"/>
    </div>
    <StartLoader v-if="isShow && isAuthorized"/>
    <div :class="isOpenModal ? 'layout-main__wrapper' : ''" v-if="!isShow && isAuthorized">

      <div class="layout-mobile-header">
        <MobileHeader
            :isMobileMenuOpened="isMobileMenuOpened"
            @toggleMobileMenu="isMobileMenuOpened = !isMobileMenuOpened"/>
      </div>
      <div :class="['layout-mobile-body', isMobileMenuOpened ? 'opened' : 'closed']">
        <MobileMenu/>
      </div>
      <div class="layout-wrapper">
        <div class="layout">
          <div class="layout__main layout__item layout-main">
            <div class="layout-main__section">
              <LeftPart/>
            </div>
            <!--            <div class="layout-main__section only-on-mobile">-->
            <!--              <PartialPayments/>-->
            <!--            </div>-->
            <div class="layout-main__section">
              <BottomButtons
                  :paymentButtons="buttons"
                  @openModal="toggleModal"
                  @openPaymentModal="openPaymentModal"
              />
            </div>
          </div>
          <div class="layout__aside layout__item layout-aside">
            <div class="layout-aside__section">
              <RightColumn :show-logo="true"/>
            </div>
            <!--            <div class="layout-aside__section">-->
            <!--              <PartialPayments/>-->
            <!--            </div>-->
          </div>
        </div>
      </div>

    </div>
    <PaymentModal
      v-model="paymentModal.open"
      :paymentType="paymentModal.type"
    >

    </PaymentModal>


    <ModalWindow
        v-show="isOpenModal"
        :show="isOpenModal"
        :scrollable="true"
        header-id="modalHeader"
        body-id="modalBody"
        @close="toggleModal"

    >
      <template #header>
        Согласуйте время для выполнения работ по заказ-наряду №{{ externalId }}
        <div class="closeModal" @click="toggleModal"></div>
      </template>
    </ModalWindow>
  </div>
</template>

<script>
import RightColumn from "../components/RightColumn/RightColumn";
// import PartialPayments from "../components/PartialPayments/PartialPayments";
import LeftPart from "../components/LeftPart";
import BottomButtons from "@/components/MainPart/BottomButtons";
import MobileHeader from "@/components/Mobile/MobileHeader";
import MobileMenu from "@/components/Mobile/MobileMenu";
import LoginPage from "@/views/Auth/login/LoginPage";
import {request} from "@/api/request";
import {mapGetters, mapMutations} from "vuex";
import StartLoader from "@/ui/StartLoader";
import ModalWindow from "@/ui/ModalWindow";
import PaymentModal from "../components/PaymentModal.vue"

export default {
  components: {
    ModalWindow,
    StartLoader,
    MobileMenu,
    MobileHeader,
    BottomButtons,
    RightColumn,
    LeftPart,
    PaymentModal,
    // PartialPayments,
    LoginPage
  },

  data() {
    return {
      isMobileMenuOpened: false,
      clientId: '',
      dataOrder: {},
      isShow: true,
      buttons: [],
      paymentModal: {
        open: false,
        type: null,
      },
      isOpenModal: false
    }
  },
  computed: {
    ...mapGetters('account', [
      "isAuthorized"
    ]),
    ...mapGetters('serviceOrder', [
      "externalId"
    ]),
  },

  methods: {
    ...mapMutations({
      loggedOut: 'account/loggedOut',
      setData: 'serviceOrder/setData'
    }),

    getData() {
      this.dataOrder = request("GET", `/api/v1/proxy/one-check-core/api/v1/service_orders/${this.$route.params.id}`)
          .then(r => {
            this.setData(r)
            this.isShow = false
          })

          .catch((e) => console.log(e))
    },

    openPaymentModal(type) {
      let selectedType = ''

      if (type === 'installment') {
        selectedType = 'installment'
      }
      else {
        selectedType = this.buttons.full.enabled ? 'full' : 'cash';
      }

      this.paymentModal.type = selectedType;
      this.paymentModal.open = true;
    },

    toggleModal() {
      this.isOpenModal = !this.isOpenModal
    }
  },
  mounted() {
    this.isShow = true
    request("GET", `/api/v1/proxy/one-check-core/api/v1/service_orders/${this.$route.params.id}/boot`)
        .then(({ data }) => {
          this.clientId = data.clientSettings.id
          this.buttons = data.settings.attributes.onlinePayment;
        })
        .catch(r => r.code === 500 && this.$router.push("/404"))
    if (this.isAuthorized) {
      this.getData()
    }

  },

  watch: {
    isMobileMenuOpened() {
      if (this.isMobileMenuOpened) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
* {
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
}

.layout-mobile-header {
  display: none;
  @media screen and (max-width: 950px) {
    display: block;
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 75px;
    box-sizing: border-box;
  }
}

.layout-mobile-body {
  position: fixed;
  height: calc(100% - 75px);
  width: 100%;
  background: #F8F8F8;
  box-sizing: border-box;
  top: 75px;
  max-width: 500px;
  transition: all linear 0.5s;

  &.opened {
    z-index: 999999;
    left: 0;
    transition: all linear 0.5s;
    //box-shadow: 8px 0px 18px 10px rgba(34, 60, 80, 0.2);
  }

  &.closed {
    left: -100%;
    transition: all linear 0.5s;
    z-index: 999999;
  }
}

.layout-wrapper {
  overflow: hidden;
  width: 1440px;
  padding: 0 100px;
  box-sizing: border-box;
  display: block;
  margin: 70px auto 0;
  @media screen and (max-width: 1540px) {
    padding: 0 50px;
    width: 100%;
  }
  @media screen and (max-width: 1240px) {
    padding: 0;
    width: 100%;
  }
  @media screen and (max-width: 950px) {
    padding: 95px 0 70px 0;
  }
}

.layout {
  display: flex;
  margin: -60px;
  align-self: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;

  @media screen and (max-width: 1540px) {
    margin: -30px;
  }

  @media screen and (max-width: 1240px) {
    margin: -10px;
  }
  @media screen and (max-width: 950px) {
    margin: 0;
  }

  &__item {
    padding: 60px;
    box-sizing: border-box;
    @media screen and (max-width: 1540px) {
      padding: 30px;
    }
    @media screen and (max-width: 1240px) {
      padding: 10px;
    }
    @media screen and (max-width: 950px) {
      padding: 0;
    }
  }

  &__main {
    box-sizing: border-box;
    flex-basis: 60%;
    @media screen and (max-width: 950px) {
      flex-basis: 100%;
      justify-content: center;
    }
  }

  &__aside {
    flex-basis: 40%;
    box-sizing: border-box;

    @media screen and (max-width: 950px) {
      flex-basis: 0;
      display: none;
    }
  }

}

.layout-main {
  box-sizing: border-box;

  &__section:not(:last-child) {
    margin: 0 0 16px 0;
    box-sizing: border-box;
  }
}

.layout-aside {
  box-sizing: border-box;

  &__section:not(:last-child) {
    margin: 0 0 22px 0;
    box-sizing: border-box;
  }
}

.only-on-mobile {
  display: none;
  @media screen and (max-width: 950px) {
    display: block;
  }
}
.layout-main__wrapper {
  @media screen and (max-width: 560px) {
    display: none;
  }
}

.closeModal {
  position: absolute;
  top: 30px;
  right: 20px;
  width: 24px;
  height: 24px;
  opacity: 0.2;
  cursor: pointer;
  transition: opacity ease 0.5s;

  &:hover {
    opacity: 1;
  }
}

.closeModal::before,
.closeModal::after {
  content: '';
  position: absolute;
  top: 10px;
  display: block;
  width: 24px;
  height: 3px;
  background: #000;
}

.closeModal::before {
  transform: rotate(45deg);
}

.closeModal::after {
  transform: rotate(-45deg);
}

</style>
