<template>
    <div class="person">
        <div class="person__title">{{ label }}</div>
        <div class="person__name">{{ name }}</div>
        <div class="person__contacts">
            <IconLabel tel="tel" :text="phone" icon="phone"></IconLabel>
        </div>
    </div>
</template>

<script>
    import IconLabel from "./IconLabel";

    export default {
        props: [
            "label",
            "name",
            "phone"
        ],
        components: {
            IconLabel
        }
    }
</script>

<style scoped lang="scss">
    .person{
        &__title{
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            margin: 0 0 16px 0;
        }
        &__name{
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            margin: 0 0 16px 0;
        }
        &__contacts{
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }

    }
</style>
