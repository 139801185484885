<template>
  <div class="container vh-100">
    <div class="row h-100 align-items-center">
      <div class="container__item col-sm-6 mx-auto p-5 shadow">
        <div class="change">
          <b-button @click="onSelectCustomer" class="mx-2" variant="primary">Клиент</b-button>
          <b-button @click="onSelectMaster" class="mx-2" variant="primary">Мастер</b-button>
        </div>
        <div v-show="roleValue === 'customer'">
          <b-form class="mt-3">
            <b-form-group
                v-show="step === 1"
                id="input-group-1"
                label="Номер телефона:"
                label-for="input-1"
            >
              <b-form-input
                  ref="inputPhone"
                  id="input-1"
                  v-model="form.phone"
                  type="phone"
                  placeholder="Введите телефон"
                  v-maska="'+7 (###) ###-##-##'"
                  required
              ></b-form-input>
            </b-form-group>

            <b-form-group
                v-show="step === 2"
                id="input-group-2"
                label="Код из смс:"
                label-for="input-2">
              <b-form-input
                  ref="inputCode"
                  id="input-2"
                  v-model="form.code"
                  placeholder="Введите код из смс"
                  required
              ></b-form-input>
            </b-form-group>
          </b-form>
          <div class="bottom-buttons">
            <div class="bottom-buttons__item" v-show="step === 1">
              <CustomButton
                  size="large"
                  type="button"
                  stylesType="primary"
                  :disabled="false"
                  :block="false"
                  @handleClick="requestCode"
              >
                Запросить код
              </CustomButton>
            </div>
            <div class="bottom-buttons__item" v-show="step === 2">
              <CustomButton
                  size="large"
                  type="button"
                  stylesType="primary"
                  :disabled="false"
                  :block="false"
                  @handleClick="requestToken"
              >
                Подтвердить
              </CustomButton>
            </div>
          </div>
        </div>
        <div v-show="roleValue === 'service_master'">
          <b-form class="mt-3">
            <b-form-group
                id="input-group-1"
                label="Email:"
                label-for="input-1"
            >
              <b-form-input
                  id="input-1"
                  v-model="form.identity"
                  type="phone"
                  placeholder="Введите email"
                  required
              ></b-form-input>
            </b-form-group>

            <b-form-group
                id="input-group-2"
                label="Пароль"
                label-for="input-2">
              <b-form-input
                  id="input-2"
                  type="password"
                  v-model="form.secret"
                  placeholder="Введите пароль"
                  required
              ></b-form-input>
            </b-form-group>
          </b-form>
          <div class="bottom-buttons">
            <div class="bottom-buttons__item">
              <CustomButton
                  size="large"
                  type="button"
                  stylesType="primary"
                  :disabled="false"
                  :block="false"
                  @handleClick="requestToken"
              >
                Войти
              </CustomButton>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>


<script>
import {request} from "@/api/request";
import CustomButton from "@/ui/CustomButton";
import {mapMutations} from "vuex";
import {useToast} from "vue-toastification";

export default {
  setup() {
    const toast = useToast()
    return {toast}
  },
  components: {CustomButton},
  props: [
    'clientId'
  ],
  data() {
    return {
      step: 1,
      form: {
        phone: '',
        code: '',
        identity: '',
        secret: ''
      },
      roleValue: 'customer',
      toastValue: {
        message: '',
        options: {
          position: "top-right",
          timeout: 4000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      loggedIn: 'account/loggedIn'
    }),
    onSelectCustomer() {
      this.roleValue = 'customer'
    },
    onSelectMaster() {
      this.roleValue = 'service_master'
      this.step = 1;
      this.form.phone = ''
      this.form.code = ''
    },
    requestToken() { // запрос токена
      if (this.roleValue === 'customer' && this.form.code.length === 0) {
        this.toast.info('Введите код из смс', this.toastValue.options)
        return
      }
      if (this.roleValue === 'service_master' && this.form.secret.length === 0) {
        this.toast.info('Введите пароль', this.toastValue.options)
        return
      }
      if (this.roleValue === 'service_master' && this.form.identity.length === 0) {
        this.toast.info('Введите email', this.toastValue.options)
        return
      }
      this.roleValue === 'customer' && request("POST", "/api/v1/customer_auth", JSON.stringify({
        data: {
          phone: this.form.phone,
          code: this.form.code
        }
      }))
          .then(r => {
            localStorage.setItem("auth_token", r.data.attributes.value)
            this.loggedIn(r.data.attributes.role)
            this.$emit('onAuth')
          })
          .catch(e => {
            this.getMessageError(e)
            e.codeValue && this.toast.error(this.toastValue.message, this.toastValue.options)
          })
      this.roleValue === 'service_master' && request("POST", "/api/v1/login", JSON.stringify({
        data: {
          identity: this.form.identity,
          secret: this.form.secret,
          type: "password",
        }
      }))
          .then(r => {
            localStorage.setItem("auth_token", r.data.attributes.value)
            this.loggedIn(r.data.attributes.role)
            this.$emit('onAuth')
          })
          .catch(e => {
            this.getMessageError(e)
            e.codeValue && this.toast.error(this.toastValue.message, this.toastValue.options)
          })
    },
    requestCode() {  // Запрос кода из смс
      const validPhone = this.form.phone.replace(/(\()|(\))|(\s)|(-)/g, "")
      if (this.step === 1 && this.form.phone.length === 0) {
        this.toast.info('Введите номер телефона', this.toastValue.options)
        return
      }
      if (this.step === 1 && this.form.phone.length !== 0 && validPhone.length !== 12) {

        this.toast.info('Введите корректный номер', this.toastValue.options)
        return
      }
      if (this.step === 2 && this.form.code.length === 0) {
        this.toast.info('Введите код из смс', this.toastValue.options)
        return
      }
      validPhone.length === 12 && request("POST", "/api/v1/customer_auth_code", JSON.stringify({
            data: {
              clientId: this.$props.clientId,
              phone: validPhone
            }
          }))
              .then(() => {
                this.step++
                this.$refs.inputCode.focus()
              })
              .catch(e => {
                this.getMessageError(e)
                e.codeValue && this.toast.error(this.toastValue.message, this.toastValue.options)
              })
    },
    getMessageError(e) {
      let message = ''
      switch (e.codeValue) {
        case "CORE_AUTH_IDENTITY_NOT_FOUND" :
          message = 'Такого пользователя не существует';
          break
        case "CORE_AUTH_VERIFICATION_FAILED":
          message = 'Вы ввели неверный код из СМС'
          break
        case "CORE_TOO_MANY_REQUESTS":
          message = 'Слишком много попыток. Пожалуйста, попробуйте еще раз позже'
          break
        case "CORE_AUTH_WRONG_PASSWORD":
          message = 'Неверный пароль'
          break
      }
      console.log(message)
      this.toastValue.message = message
    }
  },
  mounted() {
    this.$refs.inputPhone.focus()
  }
}

</script>

<style scoped lang="scss">
.container__item {
  position: relative;
}

.change {
  position: absolute;
  top: 12px;
  right: 5px;
}

.bottom-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -4px;

  &__item {
    margin: 0 4px;
  }

  @media screen and (max-width: 560px) {
    flex-direction: column;
    margin: -4px;

    &__item {
      margin: 4px;
    }
  }
}
</style>
