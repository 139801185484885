<template>
  <main class="body">
    <h1 class="body__title add-p-sm" v-if="statusOrder === 'created'">
      Добавьте фото/видео материалы к заказ-наряду №{{ externalId }}
    </h1>
    <h1 class="body__title add-p-sm" v-if="statusOrder === ['client_confirm_wait', 'client_reviewed'].includes(statusOrder)">
      Отчёт о состоянии автомобиля {{ car.brand }} {{ car.model }} {{ car.regNum }}
    </h1>
    <h1 class="body__title add-p-sm" v-if="['client_confirm_got','finished'].includes(statusOrder)">
      Результат согласования работ по заказ-наряду №{{ externalId }}
    </h1>
    <div class="body__block add-p-sm">
      <BlockWithColoredHeader title="Выполненный заказ-наряд" color="#97CF26">
        <CompletedWorks :works="works" :parts="parts"/>
      </BlockWithColoredHeader>
    </div>
    <div class="body__block add-p-sm">
      <BlockWithColoredHeader title="Результаты проверки" color="#000">
        <div style="font-weight: 400; font-size: 16px; line-height: 24px;">
          В ходе технического осмотра, были выявлены следующие дефекты, пожалуйста, ознакомьтесь со списком и одобрите
          проведение работ:
        </div>
      </BlockWithColoredHeader>
    </div>
    <div class="body__block">
      <BlockWithColoredHeader title="Рекомендации дилера" color="#FFAD1F">
        <div class="needed-works">
          <div class="needed-works__block" v-if="worksRecommended.length > 0">
            <NeededWorksBlock
                title="Необходимые работы"
                :works="worksRecommended"
                :statusOrder = "statusOrder"
                :orderId = 'orderId'
            />
          </div>
          <div class="needed-works__block" v-if="partsRecommended.length > 0">
            <NeededWorksBlock
                title="Необходимые запасные части"
                :works="partsRecommended"
                :statusOrder = "statusOrder"
                :orderId = 'orderId'
            />
          </div>
        </div>
      </BlockWithColoredHeader>
    </div>
  </main>
</template>

<script>
import BlockWithColoredHeader from "./BlockWithColoredHeader";
import CompletedWorks from "./CompletedWorks";
import NeededWorksBlock from "@/components/NeededWorksBlock";
import {mapGetters} from "vuex";

export default {
  name: "LeftPart",
  components: {
    NeededWorksBlock,
    BlockWithColoredHeader,
    CompletedWorks
  },
  data: () => ({
    isShowModal: false,
  }),
  methods: {
    toggleModal() {
      this.isShowModal = !this.isShowModal;
    },
  },
  computed: {
    ...mapGetters('serviceOrder', [
      "externalId", "statusOrder", "car", "worksRecommended", "partsRecommended", "works", "parts", "orderId"
    ])
  }
}
</script>

<style scoped lang="scss">
.body {
  &__title {
    margin: 0 0 24px 0;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;

    @media screen and (max-width: 1540px) {
      font-size: 30px;
      line-height: 38px;
    }
    @media screen and (max-width: 1240px) {
      font-size: 28px;
      line-height: 36px;
    }
    @media screen and (max-width: 950px) {
      font-size: 26px;
      line-height: 34px;
      text-align: center;
    }
    @media screen and (max-width: 560px) {
      font-size: 24px;
      line-height: 32px;
    }
    @media screen and (max-width: 470px) {
      font-size: 22px;
      line-height: 30px;
    }
    @media screen and (max-width: 370px) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__block:not(:last-child) {
    margin: 0 0 64px 0;
  }

  .needed-works{

    &__block:not(:last-child){
      padding-bottom: 32px;
      margin-bottom: 32px;
      border-bottom: 1px solid #DDDDDD;
    }
  }
}

.add-p-sm {
  @media screen and (max-width: 950px) {
    padding: 0 16px !important;
  }


}
</style>
