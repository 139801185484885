import {createApp} from 'vue'
import router from "@/router";
import BootstrapVue3 from 'bootstrap-vue-3'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import "vue-toastification/dist/index.css";

import App from './App.vue'
import store from './store'
import i18n from './i18n'
import Maska from 'maska'
import Toast from "vue-toastification";

const options = {};


createApp(App).use(i18n).use(store).use(Maska).use(Toast, options).use(BootstrapVue3).use(router).mount('#app');
