<template id="switch-button">
  <div class="switch-button-control">
    <div class="switch-button" :class="{ enabled: isEnabled }" @click="toggle" :style="{'--color': color}">
      <div class="button"></div>
    </div>
    <div class="switch-button-label">
      <span class="formLabelText"><slot/>
      <span class="workStatus">{{ workStatus === "required" ? " (критично)" : null }}</span>
</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "NeededWorkSwitch",
  model: {
    prop: "isEnabled",
    event: "toggle"
  },
  props: {
    isEnabled: Boolean,
    workStatus: {
      type: String
    },
    color: {
      type: String,
      required: false,
      default: "#4D4D4D"
    }
  },
  methods: {
    toggle() {
      this.$emit("toggle", !this.isEnabled);
    }
  },
}
</script>

<style scoped lang="scss">
#app {
  font-family: Lato, sans-serif;

  .bottom-message {
    margin-top: 20px;
    font-size: 125%;
  }
}

// For switch-button styling
.switch-button-control {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .switch-button {
    $switch-button-height: 1.6em;
    $switch-button-color: var(--color);
    $switch-button-border-thickness: 2px;
    $switch-transition: all 0.3s ease-in-out;
    $switch-is-rounded: true;

    height: $switch-button-height;
    min-width: calc(#{$switch-button-height} * 2);
    border: $switch-button-border-thickness solid $switch-button-color;
    box-shadow: inset 0px 0px $switch-button-border-thickness 0px rgba(0, 0, 0, 0.33);
    border-radius: if($switch-is-rounded, $switch-button-height, 0);
    background: #FFAD1F;
    transition: $switch-transition;

    $button-side-length: calc(
        #{$switch-button-height} - (2 * #{$switch-button-border-thickness})
    );

    cursor: pointer;

    .button {
      height: $button-side-length;
      width: $button-side-length;
      border: $switch-button-border-thickness solid $switch-button-color;
      border-radius: if($switch-is-rounded, $button-side-length, 0);
      background: white;

      transition: $switch-transition;
    }

    &.enabled {
      background-color: #FF0000;
      box-shadow: none;

      .button {
        background: white;
        transform: translateX(
                calc(#{$button-side-length} + (2 * #{$switch-button-border-thickness}))
        );
      }
    }
  }

  .switch-button-label {
    margin-left: 10px;
  }

  .formLabelText {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  .workStatus {
    color: red;
  }

  @media screen and (max-width: 560px) {
    .switch-button {
      min-width: 50px;
    }

    .formLabelText {
      font-weight: 500;
    }
  }
}
</style>
