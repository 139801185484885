<template>
    <div class="right-column">
        <div class="right-column__logo" v-if="showLogo">
            <div class="dealership-logo">
                <img src="../../assets/bmw.png" alt="bmw" title="bmw">
            </div>
        </div>
        <div class="right-column__info">
            <DealershipInfo
                    :name="dealership.entity"
                    :address="dealership.address"
                    :phone="dealership.phone"/>
        </div>
        <div class="right-column__car">
            <CarInfo
                    :brand="car.brand"
                    :model="car.model"
                    :number="car.regNum"/>
        </div>
        <div class="right-column__manager">
            <UserInfo
                    :label="$t('right_column.manager')"
                    :name="master.name"
                    :phone="master.phone"/>
        </div>
        <div class="right-column__master">
            <UserInfo
                    :label="$t('right_column.client')"
                    :name="buyer.name"
                    :phone="buyer.phone"/>
        </div>
<!--        <div class="right-column__promo">-->
<!--            <PromoAction/>-->
<!--        </div>-->
    </div>
</template>

<script>
    import {mapState} from "vuex"

    import DealershipInfo from "./DealershipInfo";
    import CarInfo from "./CarInfo";
    import UserInfo from "./UserInfo";
    // import PromoAction from "./PromoAction";

    export default {
        components: {
            UserInfo,
            CarInfo,
            DealershipInfo,
            // PromoAction
        },

        props: ['showLogo'],

        computed: {
            ...mapState({
                dealership: state => state.serviceOrder.order.attributes.dealership,
                car: state => state.serviceOrder.order.attributes.car,
                master: state => state.serviceOrder.order.attributes.master,
                buyer: state => state.serviceOrder.order.attributes.buyer,
            })
        }
    }
</script>

<style scoped lang="scss">
    .right-column {
        background-color: #F8F8F8;
        padding: 24px;
      min-width: 294px;
        @media screen and (max-width: 950px) {
            padding: 0;
        }

        &__logo {
            margin: 0 0 24px 0;
        }

        &__info {
            margin: 0 0 48px 0;
        }

        &__car{
            margin: 0 0 24px 0;
            padding: 0 0 24px 0;
            border-bottom: 1px solid #DDDDDD;
        }

        &__manager, &__master{
            margin: 0 0 40px 0;
        }

        &__promo{
            margin: 8px 0 0 0;
        }
    }
</style>
