<template>
  <div class="carousel">
    <div class="carousel__body carousel-body">
      <div class="carousel-body__prev" v-if="images?.length > 0">
        <div :class="['carousel-action', (areAllImagesOnPage || isPrevBtnDisabled) ? 'disabled' : '']" @click="prev">
          <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8.58103 14.5791C9.13966 14.0241 9.13966 13.1278 8.58103 12.5728L3.48065 7.46443L8.58103 2.42724C9.13966 1.87224 9.13966 0.975899 8.58103 0.420902C8.3105 0.151522 7.94224 0 7.55807 0C7.1739 0 6.80565 0.151522 6.53511 0.420902L0.426182 6.45414C0.153422 6.72132 0 7.08502 0 7.46443C0 7.84383 0.153422 8.20753 0.426182 8.47471L6.53511 14.5791C6.80565 14.8485 7.1739 15 7.55807 15C7.94224 15 8.3105 14.8485 8.58103 14.5791Z"
                  fill="black"/>
          </svg>
        </div>

      </div>
      <div :class="['photo-uploader__wrapper', !images?.length ? 'mr-auto' : '']"
           v-if="statusOrder === 'created' && ['client_owner', 'client_admin', 'service_master', 'service_manager'].includes(role) && images?.length > 0"
      >
        <div class="photo-uploader__icon">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4 10.6668C4 11.4002 4.6 12.0002 5.33333 12.0002C6.06667 12.0002 6.66667 11.4002 6.66667 10.6668V8.00016H9.33333C10.0667 8.00016 10.6667 7.40016 10.6667 6.66683C10.6667 5.9335 10.0667 5.3335 9.33333 5.3335H6.66667V2.66683C6.66667 1.9335 6.06667 1.3335 5.33333 1.3335C4.6 1.3335 4 1.9335 4 2.66683V5.3335H1.33333C0.6 5.3335 0 5.9335 0 6.66683C0 7.40016 0.6 8.00016 1.33333 8.00016H4V10.6668Z"
                fill="#97CF26"/>
            <path
                d="M17.3333 22.6665C19.5425 22.6665 21.3333 20.8756 21.3333 18.6665C21.3333 16.4574 19.5425 14.6665 17.3333 14.6665C15.1242 14.6665 13.3333 16.4574 13.3333 18.6665C13.3333 20.8756 15.1242 22.6665 17.3333 22.6665Z"
                fill="#97CF26"/>
            <path
                d="M28 8.00016H23.7733L22.12 6.20016C21.8715 5.92735 21.5689 5.70937 21.2314 5.56014C20.8939 5.41092 20.529 5.33372 20.16 5.3335H11.6267C11.8533 5.7335 12 6.1735 12 6.66683C12 8.1335 10.8 9.3335 9.33333 9.3335H8V10.6668C8 12.1335 6.8 13.3335 5.33333 13.3335C4.84 13.3335 4.4 13.1868 4 12.9602V26.6668C4 28.1335 5.2 29.3335 6.66667 29.3335H28C29.4667 29.3335 30.6667 28.1335 30.6667 26.6668V10.6668C30.6667 9.20016 29.4667 8.00016 28 8.00016ZM17.3333 25.3335C13.6533 25.3335 10.6667 22.3468 10.6667 18.6668C10.6667 14.9868 13.6533 12.0002 17.3333 12.0002C21.0133 12.0002 24 14.9868 24 18.6668C24 22.3468 21.0133 25.3335 17.3333 25.3335Z"
                fill="#97CF26"/>
          </svg>
          <div class="photo-uploader__text"><h3>ДОБАВИТЬ ФОТО</h3></div>
        </div>

        <form class="photo-uploader__form">
          <input type="file" class="photo-uploader__input" @change="uploaded($event)">
        </form>

      </div>
      <div class="carousel-body__images" v-if="images?.length">
        <div class="carousel-images">
          <div class="carousel-images__item" v-if="isLoaded">
            <div class="loader">loading</div>
          </div>
          <div class="carousel-images__item"
               v-for="(image, index) in visibleImages"
               :key="index">
            <div class="carousel-icon"
                 @click="onDeleteImage(index, image.id)"
                 v-if="statusOrder === 'created' && ['client_owner', 'client_admin', 'service_master', 'service_manager'].includes(role)">
              <img src="../icons/trash.svg" alt="">
            </div>
            <img
                :src="imageUrl(orderId, work.title, work.id, image.id)"
                alt="" class="carousel-image">
          </div>
        </div>
      </div>
      <!--      </div>-->

      <div class="carousel-body__next" v-if="images?.length">
        <div :class="['carousel-action', (areAllImagesOnPage || isNextBtnDisabled) ? 'disabled' : '']" @click="next">
          <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0.41897 14.5791C-0.139657 14.0241 -0.139657 13.1278 0.41897 12.5728L5.51935 7.46443L0.41897 2.42724C-0.139657 1.87224 -0.139657 0.975899 0.41897 0.420902C0.689501 0.151522 1.05776 0 1.44193 0C1.8261 0 2.19435 0.151522 2.46489 0.420902L8.57382 6.45414C8.84658 6.72132 9 7.08502 9 7.46443C9 7.84383 8.84658 8.20753 8.57382 8.47471L2.46489 14.5791C2.19435 14.8485 1.8261 15 1.44193 15C1.05776 15 0.689501 14.8485 0.41897 14.5791Z"
                  fill="black"/>
          </svg>
        </div>
      </div>
    </div>
    <!--    <div class="carousel__dots" >-->
    <!--      <div class="dots">-->
    <!--        <div class="dots__item"/>-->
    <!--        <div class="dots__item"/>-->
    <!--        <div class="dots__item"/>-->
    <!--        <div class="dots__item"/>-->
    <!--      </div>-->
    <!--    </div>-->


    <!--        {{ images }}-->


  </div>
</template>

<script>

import {request} from "@/api/request";
import {mapMutations} from "vuex";
import config from "config"

export default {
  name: "NeededWorksSlider",
  props: ['images', 'work', 'works', 'index', 'orderId', 'statusOrder', 'role'],
  data() {
    return {
      windowWidth: 0,
      countOfImages: 0,
      start: 0,
      isLoaded: false
    }
  },

  watch: {
    windowWidth() {
      const w = this.windowWidth
      const int1 = w >= 0 && w <= 421
      const int2 = (w >= 422 && w <= 575) || (w >= 951 && w <= 993)
      const int3 = (w >= 576 && w <= 713) || (w >= 994 && w <= 1389) || w >= 1541
      //const int4 = (w >= 714 && w <= 950) || (w >= 1390 && w <= 1540)

      if (int1) {
        this.countOfImages = 1
      } else if (int2) {
        this.countOfImages = 2
      } else if (int3) {
        this.countOfImages = 3
      } else {
        this.countOfImages = 4
      }
    }
  },

  computed: {
    areAllImagesOnPage() {
      return this.visibleImages.length === this.images.length
    },

    visibleImages() {
      const reversArrImages = [...this.images].reverse()
      if (this.images.length > this.countOfImages) {
        return reversArrImages.slice(this.start, this.start + this.countOfImages)
      } else {
        return reversArrImages
      }
    },

    isNextBtnDisabled() {
      return this.start > this.images.length - this.countOfImages - 1
    },

    isPrevBtnDisabled() {
      return this.start === 0
    },
  },

  methods: {
    ...mapMutations({
      changeWorkStatus: 'serviceOrder/changeWorkStatus'
    }),
    uploaded(e) {
      this.isLoaded = true
      const file = new FormData()
      file.append('file', e.target.files[0])
      this.countOfImages--
      request("POST", `/api/v1/proxy/one-check-core/api/v1/service_orders/${this.$props.orderId}/entities/${this.$props.work.title}/${this.$props.work.id}/media_objects/upload`, file)
          .then(r => {
            const works = [...this.$props.works]
            works[this.$props.index].mediaObjects.push(r.data)
            this.changeWorkStatus({works, title: works[this.$props.index].title})
          })
          .catch(e => console.log(e))
          .finally(() => {
            this.isLoaded = false
            this.countOfImages++
          })
    },
    onDeleteImage(index, id) {
      request("DELETE", `/api/v1/proxy/one-check-core/api/v1/service_orders/${this.$props.orderId}/entities/${this.$props.work.title}/${this.$props.work.id}/media_objects/${id}`)
          .then(() => {
            const works = [...this.$props.works]
            const reverseWorks = works[this.$props.index].mediaObjects
            reverseWorks.reverse().splice(this.start + index, 1)
            reverseWorks.reverse()
            this.changeWorkStatus({works, title: works[this.$props.index].title})
          })
          .catch(e => console.log(e))

    },
    onResize() {
      this.windowWidth = window.innerWidth
    },

    next() {
      if (this.isNextBtnDisabled) {
        return
      }
      this.start++
    },

    prev() {
      if (this.isPrevBtnDisabled) {
        return
      }
      this.start--
    },

    imageUrl(orderId, term, workId, imageId){
        return `${config.apiHost}/api/v1/proxy/one-check-core/api/v1/service_orders/${orderId}/entities/${term}/${workId}/media_objects/${imageId}`
    }
  },

  created() {
    this.windowWidth = window.innerWidth
    window.addEventListener('resize', this.onResize)
  },

  deactivated() {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style scoped lang="scss">
.carousel {
  &__body {

  }

  &__dots {
    margin: 16px 0 0 0;
  }
}

.carousel-body {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__images {
    //width: 100%;
    box-sizing: border-box;
    /*max-width: 650px;*/
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
}

.carousel-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  position: absolute;
  top: -10px;
  right: 2px;
  background: #CCCCCC;
  border-radius: 100%;
  cursor: pointer;
}

.carousel-body__wrapper {
  display: flex;
  align-items: center;
}

.photo-uploader__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 80px;
  border: 1px solid #97CF26;
  margin-right: 10px;

  &.mr-auto {
    margin-right: auto !important;
  }

  .photo-uploader__icon {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .photo-uploader__form {
    position: absolute;
    width: inherit;
    height: inherit;
  }

  .photo-uploader__input {
    cursor: pointer;
    z-index: 2;
    width: inherit;
    height: inherit;
    opacity: 0;

    &::file-selector-button {
      display: none;
    }
  }

  .photo-uploader__text {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #97CF26;;
  }
}

.carousel-action {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 16px;
  margin: 0 4px;

  &.disabled {
    opacity: 0.1;
  }

  &:not(:disabled):hover {
    cursor: pointer;
    background: #eee;
  }
}

.carousel-images {
  display: flex;
  margin: -2px;
  align-items: center;
  flex-wrap: wrap;
  width: 600px;
  height: 120px;
  overflow: hidden;
  justify-content: space-evenly;
  box-sizing: border-box;

  @media screen and (max-width: 575px) {
    width: unset;
  }

  &__item {
    //padding: 2px;
    max-width: 134px;
    display: flex;
    align-items: center;
    position: relative;
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 20%);
  }
}

.carousel-image {
  max-width: 134px;
  max-height: 90px;
  margin: 0;
  padding: 0;
  display: flex;
}


.dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -4px;

  &__item {
    width: 8px;
    height: 8px;
    background: #E6E6E6;
    border-radius: 50%;
    margin: 4px;
  }

  &__item:first-child {
    background: #1C69D4;
  }
}

.loader {
  overflow: hidden;
  font-size: 10px;
  text-indent: -9999em;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #65abf8;
  background: -moz-linear-gradient(left, #65abf8 10%, rgba(101, 171, 248, 0) 42%);
  background: -webkit-linear-gradient(left, #65abf8 10%, rgba(101, 171, 248, 0) 42%);
  background: -o-linear-gradient(left, #65abf8 10%, rgba(101, 171, 248, 0) 42%);
  background: -ms-linear-gradient(left, #65abf8 10%, rgba(101, 171, 248, 0) 42%);
  background: linear-gradient(to right, #65abf8 10%, rgba(101, 171, 248, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.loader:before {
  width: 50%;
  height: 50%;
  background: #65abf8;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}

.loader:after {
  background: #F8F8F8;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
